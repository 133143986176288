import styles from "../forms.module.scss";
function LabelField({ field,values }) {
  return (
    <>
      {!field?.visible || values[field.visible.key] === field.visible.value ? (
        <>
          {field?.value ? (
            <div className={styles.labelWithValue}>
              <h6>{field?.title}:</h6>
              <p>{field?.value}</p>
            </div>
          ) : (
            <h4>{field?.title}</h4>
          )}
        </>
      ) : null}
    </>
  );
}

export default LabelField;
