import * as yup from 'yup';


const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?_&])[A-Za-z\d@$!%*_?&]{6,}$/;
const phoneNumberRegex = /^[0-9]{10}$/;
const characterRegex = /^[^-\s][A-Za-z ]+$/;

export const registrationSchema = yup.object().shape({
    name: yup.string().matches(characterRegex,{message:"Invalid Name"}).required("Required Field"),
    emailId: yup.string().email("Enter a valid Email").required("Required Field"),
    mobile: yup.string().matches(phoneNumberRegex,{message:"Enter a valid phone number"}).required("Required Field"),
    password: yup.string().matches(passwordRegex,{message:"Enter a strong password"}).required("Required Field"),
    state: yup.string().when('countryCode',{
        is:(countryCode) => countryCode == '91',
        then:yup.string().required("Required Field"),
        otherwise:yup.string()
    }),
    city: yup.string().when('countryCode',{
        is:(countryCode) => countryCode == '91',
        then:yup.string().required("Required Field"),
        otherwise:yup.string()
    }),
    countryCode: yup.string(),
    // addRecaptcha: yup.number(),
    ifAgree: yup.boolean(),
    checkbox_selection : yup.string().when('ifAgree',{
        is:(ifAgree) => !ifAgree,
        then:yup.string().required("Please accept this"),
        otherwise:yup.string()
    })
})