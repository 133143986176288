import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import ImageRadio from "./ImageRadio";
import styles from "../forms.module.scss";

function RadioField({
  field,
  values,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  requiredFields,
  searchParamsObj,
}) {
  const userType = useSelector((state) => state.auth?.userType);
  const [conditions, setConditions] = useState({
    view: false,
    edit: false,
    disabled: false,
  });
  const isBold = field?.highlight;
  const quantityRef = useRef();

  useEffect(() => {
    if (field?.permission?.length) {
      const newConditions = field.permission.reduce((acc, el) => {
        if (el.role === userType) {
          return {
            view: el.permission === "view",
            edit: el.permission === "edit",
            disabled: el.permission === "disabled",
          };
        }
        return acc;
      }, conditions);
      setConditions(newConditions);
    }
  }, [field, userType]);

  const handleClick = useCallback((option, value) => {
    if (option?.isQuantity) {
      setFieldValue(field?._id, `${value}-${option?.value}`);
    } else {
      setFieldValue(field?._id, option?.value);
    }
  }, [setFieldValue, field?._id]);

  const isDisabled = useMemo(() => conditions.disabled, [conditions.disabled]);

  const isFieldDisabled = searchParamsObj[field?.title] || conditions.view;

  return (
    <>
      {!isDisabled && (
        <Form.Group>
          <Form.Label style={isBold ? { fontWeight: "500" } : {}}>
            {field?.title}
          </Form.Label>
          {field?.isFeatured ? (
            <div className={styles.imageRadioField}>
              {field?.additional?.map((option, idx) => (
                !option?.isControl ? (
                  <ImageRadio
                    key={idx}
                    imageOptions={option}
                    handleChange={(value) =>
                      setFieldValue(field?._id, value)
                    }
                    currentValue={values[field?._id]}
                  />
                ) : (
                  <Form.Check
                    key={idx}
                    type="radio"
                    name={field?._id}
                    value={option?.value}
                    checked={
                      option?.isQuantity
                        ? values[field?._id]?.split("-")[1] === option.value
                        : values[field?._id] === option.value
                    }
                    onChange={(e) => {
                      const value = option?.isQuantity
                        ? `${quantityRef.current.value}-${option.value}`
                        : option.value;
                      setFieldValue(field?._id, value);
                    }}
                    disabled={isFieldDisabled}
                    onBlur={handleBlur}
                    label={
                      <>
                        <img src={option?.image_url} alt="img" />
                        <p>{option?.value}</p>
                        {option?.isQuantity && (
                          <input
                            type="number"
                            className={styles.countInput}
                            ref={quantityRef}
                            defaultValue={1}
                            onChange={(e) =>
                              handleClick(option, e.target.value)
                            }
                            min={1}
                          />
                        )}
                      </>
                    }
                  />
                )
              ))}
            </div>
          ) : (
            field?.options?.map((option) => (
              <Form.Check
                key={option}
                type="radio"
                name={field?._id}
                value={option}
                checked={option == values[field?._id]}
                onChange={handleChange}
                onBlur={handleBlur}
                label={option}
                disabled={isFieldDisabled}
              />
            ))
          )}
          {touched[field?._id] &&
            !values[field?._id] &&
            requiredFields?.includes(field?._id) && (
              <div className="errorMessage">Required Field</div>
            )}
        </Form.Group>
      )}
    </>
  );
}

export default RadioField;
